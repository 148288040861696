.test {
  color: white;
}

.welcome {
  margin: 2rem 0 2rem 0;
}

.job {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem 0;
}

.job img {
  max-width: 30%;
  margin: 0 0 0 2rem;
}

.krmc img {
  max-width: 55%;
  margin: 4rem 1rem 0 3rem;
}

.project {
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem 0;
}

.project img {
  max-width: 25%;
  margin: 0 3rem 0 2rem;
}

@media only screen and (max-width: 640px) {
  .large {
    display: none;
  }

  .job,
  .project {
    flex-direction: column-reverse;
    align-items: baseline;
  }

  .job img {
    max-width: 60%;
    margin: 0;
  }

  .krmc img {
    max-width: 50%;
  }
}

@media only screen and (min-width: 640px) {
  .small {
    display: none;
  }
}
