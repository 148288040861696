@import url("https://fonts.googleapis.com/css2?family=Lekton&display=swap");

* {
  font-family: "Lekton", sans-serif;
  font-weight: 500;
  text-transform: none;
  background-color: #111;
  color: white;
}

body {
  margin: 0;

  line-height: 1.7em;
}

h1 {
  line-height: 2;
}

hr {
  margin: 2rem 0;
}

iframe {
  max-width: 500px;
  max-height: 500px;
}

.hello {
  margin: auto;
  max-width: 1000px;
  padding: 5px 30px;
  /* background-color: aqua; */
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* unvisited link */
a:link {
  color: #ff0000;
  text-decoration: none;
  border-bottom: 10px;
}

/* visited link */
a:visited {
  color: #ff0000;
  text-decoration: none;
  border-bottom: 1px;
}

/* mouse over link */
a:hover {
  color: white;
  text-decoration: underline;
  border-bottom: 10px;
}

/* selected link */
a:active {
  color: #ff0000;
  text-decoration: none;
  border-bottom: 1px;
}

img {
  max-width: 100%;
}

.special-img {
  max-height: 500px;
}

hr {
  border: 1px solid;
  border-color: white;
  background-color: white;
  border-top: #111;
}

@media only screen and (max-width: 640px) {
  iframe {
    max-width: 100%;
  }
}
