.footer {
  /* padding: 2rem; */
}

.icons {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.nav {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}
