.image {
  max-width: 70%;
  height: auto;
}

.left-container,
.right-header,
.body {
  color: white;
  padding: 0 0 4rem 0;
  width: 100%;
}

.right-container {
  padding: 2rem 0;
}

.right {
  text-align: right;
}

.about {
  margin: 2rem 0;
}

.p {
  margin: 0 0 3rem 0;
}

@media only screen and (min-width: 640px) {
  .left-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-header {
    justify-content: right;
    align-items: center;
  }
}

.linebreak {
  align-items: center;
}

.linebreak h3 {
  margin: 0;
}

.project-link {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;
  margin: 0 0 2rem 0rem;
}

@media only screen and (max-width: 640px) {
  .image {
    max-width: 100%;
  }
  .right {
    text-align: left;
  }
}
