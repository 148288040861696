.nav {
  background-color: #111;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.nav li:hover {
  background-color: #333;
}

.nav li:hover {
  background-color: #555;
}

.nav li a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 640px) {
  .nav {
    padding: 1rem 1rem;
  }

  .nav ul {
    gap: 0.5rem;
  }
}
